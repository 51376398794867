import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './KeyBox.module.scss';

class Input extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    disabled: false,
  };

  render() {
    const { value, disabled } = this.props;
    const val = value.split('');
    return (
      <div className={s.root}>
        <span className={s.num}>{val[0]}</span>
        <span className={s.num}>{val[1]}</span>
        <span className={s.num}>{val[2]}</span>
        <span className={s.num}>{val[3]}</span>
        <span className={s.num}>{val[4]}</span>
        <span className={s.num}>{val[5]}</span>
        {!disabled && (
          <div className={s.cursor} style={{ left: `${val.length * 20}%` }} />
        )}
      </div>
    );
  }
}

export default Input;
