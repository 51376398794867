import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Range from '../Range';
import CheckBox from '../CheckBox';

import s from './Preference.module.scss';

const handleChange = (to, callback, id) => {
  callback(to, id);
};

const Preference = observer(props => {
  const { value } = props;
  const dontKnow = typeof value === 'boolean' ? value : false;

  return (
    <>
      <Range {...props} disabled={dontKnow}>
        <div className={s.checkbox}>
          <CheckBox
            onChange={e => handleChange(e, props.onChange, props.id)}
            label="I don't know"
            id={`dontKnow${props.id}`}
            value={dontKnow}
            size="small"
          />
        </div>
      </Range>
    </>
  );
});

Preference.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  labelLevel: PropTypes.number,
};

Preference.defaultProps = {
  id: undefined,
  value: undefined,
  labelLevel: null,
};

export default Preference;
