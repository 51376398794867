import React from 'react';
import PropTypes from 'prop-types';
import s from './Dropdown.module.scss';
import Label from '../../../Label';

const Dropdown = ({ id, value, label, options, onChange }) => (
  <div>
    <Label htmlFor={`input${id}`}>{label}</Label>
    <select
      id={`input${id}`}
      value={value}
      className={s.select}
      onChange={e => onChange(e.target.value, id)}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

Dropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  value: undefined,
};

export default Dropdown;
