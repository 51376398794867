import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import ButtonGroup from '../../actions/ButtonGroup';
import Button, { Props as ButtonProps } from '../../actions/Button';
import NextQuestionButton from '../../actions/NextQuestionButton';
import Label from '../../forms/Label';

import s from './Page.module.scss';

const Page = ({
  hasTextContent,
  children,
  title,
  className,
  primaryAction,
  primaryActions,
  secondaryActions,
  backgroundImage,
  centered,
  specialMode = false,
}) => {
  const style = {};
  let ButtonEl = Button;
  if (primaryAction) {
    if (primaryAction.type === 'question') ButtonEl = NextQuestionButton;
  }
  if (backgroundImage) style.backgroundImage = `url(${backgroundImage})`;

  return (
    <div className={cN(s.root, className)} style={style}>
      {(title || hasTextContent) && (
        <div className={cN(s.inner, { [s.centered]: centered })}>
          {title && (
            <Label className={cN({ [s.specialMode]: specialMode })}>
              {title}
            </Label>
          )}
          {hasTextContent && <div className={s.content}>{children}</div>}
        </div>
      )}
      {!hasTextContent && children}

      {secondaryActions && (
        <ButtonGroup>
          {secondaryActions.map(action => (
            <Button key={action.content} {...action} />
          ))}
        </ButtonGroup>
      )}

      {primaryAction && <ButtonEl {...primaryAction} variation="primary" />}
      {primaryActions && (
        <ButtonGroup>
          {primaryActions.map(action => (
            <Button key={action.content} {...action} variation="primary" />
          ))}
        </ButtonGroup>
      )}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  hasTextContent: PropTypes.bool,
  primaryAction: PropTypes.shape(ButtonProps),
  primaryActions: PropTypes.arrayOf(PropTypes.shape(ButtonProps)),
  secondaryActions: PropTypes.arrayOf(PropTypes.shape(ButtonProps)),
  backgroundImage: PropTypes.string,
  breadcrumbs: PropTypes.bool,
};

Page.defaultProps = {
  className: null,
  title: null,
  hasTextContent: true,
  primaryAction: null,
  primaryActions: null,
  secondaryActions: null,
  backgroundImage: null,
  breadcrumbs: false,
};

export default Page;
