import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './Color.module.scss';
import colors from './colors';
import Label from '../../../Label';
import Range from '../Range';

const Color = ({ id, label, value, onChange }) => (
  <>
    <Label htmlFor={`input${id}`}>{label}</Label>
    <div>
      <ul className={s.root}>
        {colors.map(color => (
          <li className={s.item} key={color.value}>
            <button
              className={cN(s.button, {
                [s.active]: value.color === color.value,
              })}
              onClick={() => onChange({ ...value, color: color.value }, id)}
              type="button"
            >
              <svg className={s.color} viewBox="0 0 100 100">
                <rect
                  width={100}
                  height={100}
                  x={0}
                  y={0}
                  fill={color.value}
                  opacity={value.intensity ? value.intensity / 100 : 1}
                />
              </svg>
            </button>
          </li>
        ))}
      </ul>
      <Range
        onChange={val => onChange({ ...value, intensity: val }, id)}
        label="Intensity"
        labelLevel={2}
        labelHidden
        value={value.intensity || 100}
      />
    </div>
  </>
);

Color.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      color: PropTypes.string,
      intensity: PropTypes.number,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

Color.defaultProps = {
  id: undefined,
  value: { color: null, intensity: 100 },
};

export default Color;
