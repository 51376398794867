import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../actions';
import { Title } from '../../text';

import s from './Card.module.scss';

const Card = ({ title, label, children, primaryAction }) => (
  <div className={s.root}>
    {title && (
      <header className={s.header}>
        <Title>{title}</Title>
        {label}
      </header>
    )}
    {children && <div className={s.section}>{children}</div>}
    {primaryAction && (
      <footer className={s.footer}>
        <Button {...primaryAction} variation="primary" />
      </footer>
    )}
  </div>
);

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  title: null,
  children: null,
};

export default Card;
