import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import s from './Repeater.module.scss';
import Label from '../../../Label';

class Repeater extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    labelHidden: PropTypes.bool,
  };

  static defaultProps = {
    id: undefined,
    labelHidden: false,
  };

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { value, onChange, id } = this.props;
    if (!value) onChange([{ id: 1, value: '' }], id);
  }

  handleChange = (newValue, fieldId) => {
    const { onChange, value, id } = this.props;
    const nValues = [...value];
    const el = nValues.find(v => v.id === fieldId);
    el.value = newValue;
    onChange(nValues, id);
  };

  addRow = () => {
    const { value, onChange, id } = this.props;
    if (value) value.push({ id: value.length + 1, value: '' });
    onChange(value, id);
  };

  render() {
    const { labelHidden, label, id, value } = this.props;

    return (
      <div className={s.root}>
        <Label hidden={labelHidden} htmlFor={`input${id}`}>
          {label}
        </Label>
        <div className={s.fields}>
          {value &&
            value.map(val => (
              <Text
                label="Repeater input"
                labelHidden
                key={val.id}
                {...val}
                onChange={this.handleChange}
              />
            ))}
        </div>
        <button className={s.buttonAdd} onClick={this.addRow} type="button">
          <span>Add row</span>
        </button>
      </div>
    );
  }
}

export default Repeater;
