import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

// Classes vertical and horizontal are dynamically imported
// eslint-disable-next-line css-modules/no-unused-class
import s from './ButtonGroup.module.scss';

const ButtonGroup = ({ children, variation }) => (
  <div className={cN(s.root, s[variation])}>
    {children.map(child => (
      <div key={child.key} className={s.button}>
        {child}
      </div>
    ))}
  </div>
);

ButtonGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  variation: PropTypes.oneOf(['vertical', 'horizontal']),
};

ButtonGroup.defaultProps = {
  variation: 'vertical',
};

export default ButtonGroup;
