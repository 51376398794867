import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './Question.module.scss';

import * as Input from '../Input';
import Label from '../Label';
import { Chapter } from '../../layout';

class Question extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      PropTypes.shape(),
    ]),
    onChoice: PropTypes.func.isRequired,
    onNext: PropTypes.func,
  };

  static defaultProps = {
    placeholder: null,
    options: undefined,
    onNext: null,
  };

  getQuestion = params => {
    const { onGetAnswer } = this.props;
    const props = {
      id: params.id,
      label: params.label,
      labelHidden: params.labelHidden,
      labelLevel: params.level,
      labels: params.labels,
      image: params.image,
      multiple: params.multiple,
      key: params.id,
      onChange: this.handleChoice,
      onNext: this.handleNext,
      onGetAnswer: params.onGetAnswer,
      value: params.answer || onGetAnswer(params.id),
      parentAnswer: params.parentAnswer,
      max: params.max,
    };

    switch (params.type) {
      case 'likert':
        return <Input.Likert {...props} />;
      case 'color':
        return <Input.Color {...props} />;
      case 'range':
        return <Input.Range {...props} />;
      case 'preference':
        return <Input.Preference {...props} />;
      case 'repeater':
        return <Input.Repeater {...props} />;
      case 'location':
        return <Input.Location {...props} />;
      case 'choicelist': {
        let opts = params.options;
        if (!Array.isArray(params.options)) {
          opts = params.options.cases[params.parentAnswer || 'default'] || [];
        }
        return <Input.ChoiceList {...props} options={opts} />;
      }
      case 'text':
      case 'number':
      case 'tel':
        return (
          <Input.Text
            {...props}
            type={params.type}
            placeholder={params.placeholder}
          />
        );
      case 'multiple':
        return params.items.map(this.getQuestion);
      case 'chapter':
        return (
          <Chapter {...props} title={params.label} content={params.content} />
        );
      default:
        return <p>No valid type specified</p>;
    }
  };

  handleChoice = (value, id) => {
    const { onChoice } = this.props;
    if (value !== null) onChoice(id, value);
  };

  handleNext = () => {
    const { onNext, isAnswered } = this.props;
    if (isAnswered) onNext();
  };

  render() {
    const { id, label, type } = this.props;
    const answers = this.getQuestion(this.props);
    return (
      <form action="/" onSubmit={e => e.preventDefault()} className={s.root}>
        {type === 'multiple' && <Label htmlFor={`input${id}`}>{label}</Label>}
        {type === 'multiple' ? <div>{answers}</div> : answers}
      </form>
    );
  }
}

export default Question;
