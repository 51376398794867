/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';

import s from './Location.module.scss';
import gs from '../../input.module.scss';

import Label from '../../../Label';

const Location = ({ labelHidden, id, label, onChange }) => (
  <>
    <Label hidden={labelHidden} htmlFor={`input${id}`}>
      {label}
    </Label>
    <Geosuggest
      autoActivateFirstSuggest
      inputClassName={gs.root}
      onChange={e => onChange(e, id)}
      onActivateSuggest={e => onChange(e, id)}
      suggestItemClassName={s.item}
      suggestItemActiveClassName={s.active}
      suggestsClassName={s.list}
    />
  </>
);

Location.propTypes = {
  labelHidden: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Location.defaultProps = {
  labelHidden: false,
  value: undefined,
};

export default Location;
