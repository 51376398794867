import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './VisuallyHidden.module.css';

const VisuallyHidden = ({ children, className }) => (
  <span className={cN(s.VisuallyHidden, className)}>{children}</span>
);

VisuallyHidden.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};

VisuallyHidden.defaultProps = {
  className: '',
};

export default VisuallyHidden;
