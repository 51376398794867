import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './ChoiceList.module.scss';
import Label from '../../../Label';

class ChoiceList extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    onChange: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    label: PropTypes.string.isRequired,
    labelLevel: PropTypes.number,
    multiple: PropTypes.bool,
    hasCustomContent: PropTypes.bool,
    max: PropTypes.number,
  };

  static defaultProps = {
    options: [
      { label: 'Disagree a lot', value: 0 },
      { label: 'Disagree', value: 1 },
      { label: 'Neutral', value: 2 },
      { label: 'Agree', value: 3 },
      { label: 'Agree a lot', value: 4 },
    ],
    id: undefined,
    value: undefined,
    multiple: false,
    hasCustomContent: false,
    labelLevel: null,
    max: 99,
  };

  state = {
    options: [],
  };

  delay = 500;

  timeout = null;

  interval = null;

  componentDidMount() {
    if (!this.$el) return;
    this.timeout = setTimeout(this.mountComponents, this.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  mountComponents = () => {
    this.$el.classList.add(s.mounted);
    this.interval = setInterval(this.mountOptions, 150);
  };

  mountOptions = () => {
    const { options } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const current = this.state.options;
    const curLength = current.length;
    if (options.length > curLength) {
      this.setState({ options: [...current, options[curLength]] });
    } else {
      clearInterval(this.interval);
    }
  };

  render() {
    const {
      label,
      labelLevel,
      id,
      value,
      onChange,
      multiple,
      hasCustomContent,
      max,
    } = this.props;
    const { options } = this.state;
    const inputType = multiple ? 'checkbox' : 'radio';

    return (
      <>
        <Label htmlFor={`input${id}`} level={labelLevel}>
          {label}
        </Label>
        <fieldset
          ref={el => {
            this.$el = el;
          }}
          className={s.fieldset}
          id={`input${id}`}
        >
          <ul className={cN(s.root, { [s.grid]: hasCustomContent })}>
            {options.map(option => {
              let isChecked = option.value === value;
              if (multiple && value) {
                isChecked = value.includes(option.value);
              }
              const inputId = option.value.replace(' ', '');
              return (
                <li className={s.choice} key={option.value}>
                  <label className={s.label} htmlFor={inputId}>
                    <input
                      className={s.radio}
                      id={inputId}
                      checked={isChecked}
                      name={inputId}
                      type={inputType}
                      onChange={() => {
                        let nValue = option.value;
                        if (value) {
                          if (multiple) {
                            const index = value.indexOf(option.value);
                            if (index > -1) {
                              value.splice(index, 1);
                              nValue = value;
                            } else if (value.length >= max) {
                              // eslint-disable-next-line no-alert
                              alert(`You can choose up to ${max} options`);
                              return;
                            } else {
                              nValue = [...value, option.value];
                            }
                          }
                        } else if (multiple) {
                          nValue = [option.value];
                        }
                        onChange(nValue, id);
                      }}
                    />
                    {hasCustomContent && (
                      <div className={s.content}>{option.content}</div>
                    )}
                    {option.image && (
                      <img
                        className={s.image}
                        src={option.image}
                        alt={option.label}
                      />
                    )}
                    {!(hasCustomContent && option.label) && (
                      <div className={s.text}>
                        <span>{option.label}</span>
                        {option.helpText && <small>{option.helpText}</small>}
                      </div>
                    )}
                  </label>
                </li>
              );
            })}
          </ul>
        </fieldset>
      </>
    );
  }
}

export default ChoiceList;
