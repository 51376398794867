/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './Label.module.scss';
import VisuallyHidden from '../../layout/VisuallyHidden';

class Label extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    const { children, level } = this.props;
    if (level === null) this.createLabel(children);
  }

  componentWillReceiveProps(newProps) {
    const { children } = this.props;
    if (children === newProps.children) return;
    this.$el.style.height = '';

    this.setState({ text: '' });
    this.createLabel(newProps.children);
  }

  createLabel(text) {
    const height = this.$el.offsetHeight;
    this.$el.style.height = `${height}px`;
    if (typeof text !== 'string') return;
    this.setState({ text });
  }

  render() {
    const { htmlFor, hidden, level, children, className } = this.props;
    const { text } = this.state;

    const El = htmlFor ? 'label' : 'h2';
    const classNames = cN(
      className,
      s.root,
      { [s.mounted]: text || level === 2 },
      { [s.secondary]: level === 2 },
      { [s.primary]: level === null },
      { [s.hidden]: level === 3 },
      { [s.level4]: level === 4 },
    );

    const label = (
      <El
        ref={el => {
          this.$el = el;
        }}
        className={classNames}
        htmlFor={htmlFor}
      >
        {text || children}
      </El>
    );
    if (hidden) {
      return <VisuallyHidden>{label}</VisuallyHidden>;
    }
    return label;
  }
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  level: PropTypes.number,
};

Label.defaultProps = {
  htmlFor: null,
  hidden: false,
  level: null,
  className: null,
};

export default Label;
