import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';

class Qr extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
  };

  static defaultProps = {
    onChange: null,
    id: undefined,
  };

  delay = 300;

  state = {
    error: null,
  };

  handleManual = () => {
    // eslint-disable-next-line no-alert
    const result = prompt('Enter the code');
    this.handleScan(result);
  };

  handleError = err => {
    this.setState({ error: err.message });
  };

  handleScan = result => {
    const { onChange, id } = this.props;
    if (result) {
      if (onChange) onChange(result, id);
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div>
        <QrReader
          delay={this.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
        />
        {error && (
          <p>Could not load the QR scanner. Please enter the code manually</p>
        )}
        <small>
          <button onClick={this.handleManual} type="button">
            Or enter manually
          </button>
        </small>
      </div>
    );
  }
}

export default Qr;
