import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './CheckBox.module.scss';

const CheckBox = ({ label: theLabel, id, value, size, onChange }) => (
  <label
    onClick={e => e.stopPropagation()}
    className={cN(s.root, { [s.small]: size === 'small' })}
    htmlFor={`input${id}`}
  >
    <input
      className={s.checkbox}
      type="checkbox"
      id={`input${id}`}
      name={`input${id}`}
      checked={value}
      onChange={() => onChange(!value, id)}
    />
    <span className={s.check} />
    {/* eslint-disable-next-line react/no-danger */}
    <span className={s.text} dangerouslySetInnerHTML={{ __html: theLabel }} />
  </label>
);

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
};

CheckBox.defaultProps = {
  size: null,
};

export default CheckBox;
