import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import s from './Chapter.module.scss';

@inject('questionnaire')
@observer
class Chapter extends Component {
  static propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
    questionnaire: PropTypes.shape({
      submit: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    title: null,
    image: null,
    content: null,
  };

  componentDidMount() {
    const { questionnaire } = this.props;
    questionnaire.submit(true);
  }

  render() {
    const { title, image, content } = this.props;
    return (
      <div className={s.root}>
        {title && <h2 className={s.title}>{title}</h2>}
        {image && <img src={image} className={s.image} alt="" />}
        {content && <p>{content}</p>}
      </div>
    );
  }
}

export default Chapter;
