import React from 'react';
import PropTypes from 'prop-types';

import s from './Title.module.scss';

const Title = ({ children, level }) => {
  const El = level;
  return <El className={s.root}>{children}</El>;
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  level: PropTypes.string,
};

Title.defaultProps = {
  level: 'h2',
};

export default Title;
