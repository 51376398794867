import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '../index';

import s from './TextContainer.module.scss';

const TextContainer = ({ children, title, intro }) => (
  <div className={s.root}>
    {title && <Title level="h1">{title}</Title>}
    {intro && <p className={s.intro}>{intro}</p>}
    {children}
  </div>
);

TextContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string,
  intro: PropTypes.string,
};

TextContainer.defaultProps = {
  title: null,
  intro: null,
};

export default TextContainer;
