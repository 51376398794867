import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import s from './Header.module.scss';

@inject('common')
@observer
class Header extends Component {
  static propTypes = {
    common: PropTypes.shape({
      toggleCredits: PropTypes.func.isRequired,
    }).isRequired,
    breadcrumbs: PropTypes.bool,
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    breadcrumbs: true,
  };

  handleReload = () => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const forSure = confirm('Are you sure you want to reload this page?');
    if (forSure) window.location.reload();
  };

  render() {
    const {
      common,
      breadcrumbs,
      history,
      location: { pathname },
    } = this.props;
    const isQuestionnaire = pathname.indexOf('questionnaire') >= 0;
    const isMobile = window.innerWidth < 760;
    let showBreadcrumbs = !['/', '/r'].includes(pathname.slice(0, 2));
    let showHeader = true;

    if (isMobile && isQuestionnaire) {
      showHeader = false;
    }

    let questionIndex = 0;
    if (isQuestionnaire) {
      showBreadcrumbs = false;
      const splittedPath = pathname.split('/');
      questionIndex = splittedPath[splittedPath.length - 1];
    }

    let to = history.goBack;
    if (pathname === '/orders') to = () => history.push('/');

    return (
      <header className={cN(s.root, { [s.hide]: !showHeader })}>
        {breadcrumbs &&
          showBreadcrumbs && (
            <button
              className={cN(s.breadcrumb, { [s.hide]: !showBreadcrumbs })}
              onClick={to}
              type="button"
            >
              &lt; Previous page
            </button>
          )}

        {isQuestionnaire && (
          <div>
            <button
              onClick={() => {
                if (questionIndex === '0') {
                  history.push('/');
                } else {
                  history.goBack();
                }
              }}
              className={s.button}
              type="button"
            >
              ←
            </button>

            <button
              onClick={this.handleReload}
              className={s.button}
              type="button"
            >
              ↺
            </button>
          </div>
        )}

        <button onClick={common.toggleCredits} className={s.info} type="button">
          i
        </button>
      </header>
    );
  }
}

export default withRouter(Header);
