import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import { VisuallyHidden } from '../../layout';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Badge.module.scss';

const Badge = ({ children, progress, variation }) => (
  <span className={cN(s.root, s[variation])}>
    {variation && <VisuallyHidden>{variation}</VisuallyHidden>}
    {progress && (
      <span className={cN(s.progressCircle, s[progress])}>
        <VisuallyHidden>{progress}</VisuallyHidden>
      </span>
    )}
    {children}
  </span>
);

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  progress: PropTypes.oneOf(['incomplete', 'partiallyComplete', 'complete']),
  variation: PropTypes.oneOf([
    'success',
    'info',
    'attention',
    'warning',
    'new',
  ]),
};

Badge.defaultProps = {
  progress: null,
  variation: null,
};

export default Badge;
