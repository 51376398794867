import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Range from '../Range';

const Likert = ({ onChange, value, id, t, label, labelLevel }) => {
  const labels = [
    t('Strongly disagree'),
    // t('Disagree'),
    // t('Neutral'),
    // t('Agree'),
    t('Strongly agree'),
  ];
  return (
    <Range
      label={label}
      labelLevel={labelLevel}
      id={id}
      stepSize={1}
      value={value}
      onChange={onChange}
      labels={labels}
      // min={1}
      // max={5}
    />
  );
};

Likert.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelLevel: PropTypes.number,
};

Likert.defaultProps = {
  id: undefined,
  value: undefined,
  labelLevel: null,
};

export default withNamespaces()(Likert);
