import React from 'react';
import PropTypes from 'prop-types';

import s from './Wrapper.module.scss';

const Wrapper = ({ children }) => <div className={s.root}>{children}</div>;

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Wrapper;
