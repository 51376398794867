import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './Message.module.scss';

const Message = ({ children, hide }) => (
  <div className={cN(s.root, { [s.hide]: hide })}>{children}</div>
);

Message.propTypes = {
  children: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
};

export default Message;
