import React, { Component } from 'react';
import cN from 'classnames';
import PropTypes from 'prop-types';

import Label from '../../../Label';

import s from '../../input.module.scss';

class Text extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    labelHidden: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelLevel: PropTypes.number,
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    onChange: null,
    onNext: null,
    placeholder: 'Type here',
    value: '',
    id: undefined,
    type: 'text',
    max: 30,
    min: 0,
    labelHidden: false,
    autoFocus: false,
    labelLevel: null,
  };

  handleKeyDown = event => {
    const { onNext } = this.props;
    if (event.key === 'Enter') {
      this.$input.blur();
      if (onNext) onNext();
    }
  };

  handleChange = event => {
    const { onChange, id } = this.props;
    const { value } = event.target;
    if (onChange) {
      onChange(value, id);
    }
  };

  render() {
    const {
      label,
      labelLevel,
      labelHidden,
      id,
      value,
      type,
      placeholder,
      min,
      max,
      autoFocus,
    } = this.props;

    let minValue = null;
    if (type === 'number') minValue = 10;
    if (min) minValue = min;

    return (
      <div>
        <Label htmlFor={`input${id}`} level={labelLevel} hidden={labelHidden}>
          {label}
        </Label>
        <input
          id={`input${id}`}
          className={cN(s.root, { [s.leveled]: labelLevel === 2 })}
          minLength={minValue}
          min={minValue}
          maxLength={max}
          type={type}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          ref={el => {
            this.$input = el;
          }}
        />
      </div>
    );
  }
}

export default Text;
