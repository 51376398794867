import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import { Link } from 'react-router-dom';

// eslint-disable-next-line css-modules/no-unused-class
import s from './NextQuestionButton.module.scss';

export const Props = {
  /** Callback when clicked */
  onClick: PropTypes.func,
  /** Show a disabled state */
  disabled: PropTypes.bool,
  /** The button content */
  children: PropTypes.string,
  /** The button content as prop */
  content: PropTypes.string,
  /** Local link to go to */
  to: PropTypes.string,
  /** Additional classnames to add */
  className: PropTypes.string,
  /** Button variation */
  variation: PropTypes.oneOf(['default', 'subdued', 'primary']),
};

class Button extends Component {
  static propTypes = Props;

  static defaultProps = {
    onClick: null,
    disabled: false,
    to: null,
    variation: 'default',
    className: null,
    children: '',
    content: '',
  };

  handleClick = e => {
    const { onClick, disabled } = this.props;
    if (disabled) e.preventDefault();
    if (onClick) onClick();
  };

  render() {
    const {
      children,
      content,
      id,
      disabled,
      to,
      className,
      variation,
    } = this.props;
    const classNames = cN(s.root, s[variation], className);

    const props = {
      disabled,
      className: classNames,
      onClick: this.handleClick,
    };
    if (id) props.id = id;

    let inner = <span className={s.inner}>{content || children}</span>;
    if (disabled)
      inner = <span className={s.inner}>Complete all inputs to continue</span>;

    if (to) {
      return (
        <Link to={to} {...props}>
          {inner}
        </Link>
      );
    }
    return (
      <button {...props} type="button">
        {inner}
      </button>
    );
  }
}

export default Button;
