/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import s from './Range.module.scss';
import Label from '../../../Label';

class Range extends Component {
  saveTimeout = null;

  hasMoved = false;

  componentDidMount() {
    const { value } = this.props;
    this.setValue(value);
  }

  handleChange = value => {
    clearTimeout(this.saveTimeout);
    this.setValue(value);
    this.hasMoved = true;
    this.saveTimeout = setTimeout(this.save, 300);
  };

  handleClick = e => {
    // const { value, onChange } = this.props;
    if (e.target.type === 'checkbox') {
      // console.info('a');
      // onChange(!value);
      return;
    }

    const padding = 30;
    const rect = this.$root.getBoundingClientRect();
    const width = rect.width - padding * 2;
    const x = e.clientX - rect.left - padding;
    let nValue;
    if (x > width) {
      nValue = 100;
    } else if (x < 0) {
      nValue = 0;
    } else {
      nValue = (x * 100) / width;
    }
    this.handleChange(nValue);
  };

  save = () => {
    const { onChange, id } = this.props;
    if (onChange) onChange(this.$el.value, id);
  };

  setValue = value => {
    this.$el.value = value;
  };

  render() {
    const {
      min,
      max,
      disabled,
      id,
      stepSize,
      label,
      labelLevel,
      labelHidden,
      labels,
      image,
      children,
    } = this.props;
    const theLabel = (
      <Label hidden={labelHidden} htmlFor={`input${id}`} level={labelLevel}>
        {label}
      </Label>
    );

    return (
      <>
        {!labelLevel && theLabel}
        <div
          ref={el => {
            this.$root = el;
          }}
          onClick={this.handleClick}
          className={cN(
            s.root,
            { [s.hasMoved]: this.hasMoved },
            { [s.disabled]: disabled },
            { [s.hasChildren]: children },
          )}
        >
          {labelLevel && theLabel}
          {image && <img src={image} alt="" className={s.image} />}
          <input
            ref={el => {
              this.$el = el;
            }}
            onChange={e => this.handleChange(e.target.value)}
            className={s.input}
            min={min}
            max={max}
            disabled={disabled}
            type="range"
            step={stepSize}
            id={`input${id}`}
          />
          {labels && (
            <ul className={s.labels}>
              {labels.map((l, index) => (
                <li key={l} className={s.label}>
                  <button
                    className={s.button}
                    onClick={() => {
                      let newValue = index * (max / labels.length) + min;
                      if (labels.length === 2)
                        newValue = index === 0 ? min : max;
                      this.handleChange(newValue);
                    }}
                    type="button"
                  >
                    {l}
                  </button>
                </li>
              ))}
            </ul>
          )}
          {children}
        </div>
      </>
    );
  }
}
Range.propTypes = {
  children: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  stepSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  labelLevel: PropTypes.number,
  labelHidden: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
};

Range.defaultProps = {
  children: null,
  min: 0,
  max: 100,
  disabled: false,
  id: undefined,
  value: 50,
  stepSize: 1,
  labelHidden: false,
  labelLevel: null,
  labels: null,
  image: null,
};

export default Range;
