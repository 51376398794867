function shuffle(a) {
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line  no-param-reassign
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const colors = [
  { label: 'Black', value: '#000' },
  { label: 'Dark grey', value: '#626262' },
  { label: 'Medium grey', value: '#808080' },
  { label: 'Light grey', value: '#C0C0C0' },
  { label: 'Tan', value: '#90713A' },
  { label: 'Brown', value: '#562C06' },
  { label: 'Dark Green', value: '#006411' },
  { label: 'Green', value: '#1FB715' },
  { label: 'Cyan', value: '#01ABEA' },
  { label: 'Blue', value: '#0001D3' },
  { label: 'Purple', value: '#4701A5' },
  { label: 'Magenta', value: '#F20784' },
  { label: 'Red', value: '#DD0907' },
  { label: 'Orange', value: '#FF6405' },
  { label: 'Yellow', value: '#FBF304' },
  { label: 'White', value: '#fff' },
];

shuffle(colors);

export default colors;
